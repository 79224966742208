<template>
  <div class="register">
    <div class="register-container-img">
      <img src="./image/pc.jpg" v-if="$store.state.pageset.pcshow" />
      <img src="./image/mobile.jpg" v-else />
    </div>
    <div class="register-container">
      <div class="register-container-form">
        <h1>创建账户</h1>
        <el-form
          ref="registerForm"
          :model="form"
          :rules="formRules"
          v-if="$store.state.pageset.pcshow"
        >
          <el-row :gutter="40" class="row">
            <el-col :span="12">
              <el-form-item label="姓名" prop="userName">
                <el-input v-model="form.userName" autofocus></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12"
              ><el-form-item label="手机号" prop="phonenumber">
                <el-input v-model="form.phonenumber"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="40">
            <el-col :span="12">
              <el-form-item label="公司名称" prop="company">
                <el-input v-model="form.company"></el-input> </el-form-item
            ></el-col>
            <el-col :span="12">
              <el-form-item label="所在地（省/市/区）" prop="adress">
                <el-input v-model="form.adress"></el-input> </el-form-item
            ></el-col>
          </el-row>
          <el-row :gutter="40">
            <el-col :span="12">
              <el-form-item prop="email" label="邮箱">
                <el-input v-model="form.email"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <div class="dic-line"></div>
          <el-row :gutter="40">
            <el-col :span="12">
              <el-form-item label="密码" prop="password">
                <el-input
                  v-model="form.password"
                  type="password"
                  autocomplete="off"
                  autocorrect="off"
                  autocapitalize="none"
                  aria-label="password"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="确认密码" prop="confirmPwd">
                <el-input
                  v-model="form.confirmPwd"
                  type="password"
                  autocomplete="off"
                  autocorrect="off"
                  autocapitalize="none"
                  aria-label="password"
                ></el-input> </el-form-item
            ></el-col>
          </el-row>
          <el-row :gutter="40">
            <el-col :span="12">
              <el-form-item prop="identifyCode">
                <p style="line-height: 40px">验证码</p>
                <div class="bottom_margin--large">
                  <i
                    class="el-icon-refresh-right"
                    @click="refreshCode"
                    title="点击刷新验证码"
                  ></i>
                  <span style="cursor: pointer; margin-top: 10px">
                    <Sidentify :identifyCode="identifyCode"></Sidentify>
                  </span>
                </div>
                <p style="line-height: 40px">请输入图片中的字符</p>
                <el-input v-model="form.identifyCode"></el-input> </el-form-item
            ></el-col>
          </el-row>
          <el-row :gutter="40">
            <el-col :span="20">
              <el-form-item>
                <input
                  type="checkbox"
                  :checked="agreement"
                  id="form-input-consent"
                  v-model="agreement"
                />
                <label for="form-input-consent" class="file-window"
                  >我了解并同意七腾机器人<a
                    href="http://oa.7tyun.com:9300/statics/SevnceJoe-V1.2.html"
                    >客户隐私声明</a
                  >和<a href="http://oa.7tyun.com:9300/statics/SevnceJoe-useTerms.html"
                    >使用条款</a
                  >
                </label>
              </el-form-item></el-col
            >
          </el-row>
        </el-form>
        <el-form ref="registerForm" :model="form" :rules="formRules" v-else>
          <el-form-item label="姓名" prop="userName">
            <el-input v-model="form.userName" autofocus></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="phonenumber">
            <el-input v-model="form.phonenumber"></el-input>
          </el-form-item>
          <el-form-item label="公司名称" prop="company">
            <el-input v-model="form.company"></el-input>
          </el-form-item>

          <el-form-item label="所在地（省/市/区）" prop="adress">
            <el-input v-model="form.adress"></el-input>
          </el-form-item>
          <el-form-item prop="email" label="邮箱">
            <el-input v-model="form.email"></el-input>
          </el-form-item>
          <div class="dic-line"></div>
          <el-form-item label="密码" prop="password">
            <el-input
              v-model="form.password"
              type="password"
              autocomplete="off"
              autocorrect="off"
              autocapitalize="none"
              aria-label="password"
            ></el-input>
          </el-form-item>

          <el-form-item label="确认密码" prop="confirmPwd">
            <el-input
              v-model="form.confirmPwd"
              type="password"
              autocomplete="off"
              autocorrect="off"
              autocapitalize="none"
              aria-label="password"
            ></el-input>
          </el-form-item>
          <el-form-item prop="confirmPwd">
            <p style="line-height: 40px">验证码</p>
            <div class="bottom_margin--large">
              <i
                class="el-icon-refresh-right"
                @click="refreshCode"
                title="点击刷新验证码"
              ></i>
              <span style="cursor: pointer; margin-top: 10px">
                <Sidentify :identifyCode="identifyCode"></Sidentify>
              </span>
            </div>
            <p style="line-height: 40px">请输入图片中的字符</p>
            <el-input v-model="form.identifyCode"></el-input>
          </el-form-item>
          <el-form-item>
            <input
              type="checkbox"
              :checked="agreement"
              id="form-input-consent"
              v-model="agreement"
            />
            <label for="form-input-consent" class="file-window"
              >我了解并同意七腾机器人<a
                href="http://oa.7tyun.com:9300/statics/SevnceJoe-V1.2.html"
                >客户隐私声明</a
              >和<a href="http://oa.7tyun.com:9300/statics/SevnceJoe-useTerms.html"
                >使用条款</a
              >
            </label>
          </el-form-item>
        </el-form>
        <button type="submit" class="tds-btn-full" @click="submitRe">确定</button>
        <button class="tds-btn-tertiary" @click="cancelRe">取消</button>
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible" width="30%">
      <span>注册成功,{{ countdown }}秒后自动跳转到登录页面</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="openLogin('cancel')">取 消</el-button>
        <el-button type="primary" @click="openLogin('submit')">直接跳转</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import index from "./index.js";

export default index;
</script>
<style scoped lang="scss">
@import "index.scss";
input[type="checkbox"]:active {
  background-color: #3e6ae1;
}

/deep/.el-input__inner {
  background-color: #f4f4f4;
}

/deep/.el-form-item {
  margin-bottom: 5px;
}
</style>
