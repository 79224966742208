import Sidentify from "./component/identify.vue";//验证码组件
import { registerUser } from '@/api/login';
import { validPhone, validEmail } from '@/utils/validate';
export default {
    components: {
        Sidentify
    },
    data() {
        // 密码校验
        let validatePass = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入密码'));
            } else {
                if (this.form.confirmPwd !== '') {
                    this.$refs.registerForm.validateField('checkPass');
                }
                callback();
            }
        };
        // 确认密码校验
        let validatePass2 = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请再次输入密码'));
            } else if (value !== this.form.password) {
                callback(new Error('两次输入密码不一致!'));
            } else {
                callback();
            }
        };
        // 邮箱校验
        let validateEmail = (rule, value, callback) => {
            if (!validEmail(value)) {
                callback(new Error('请输入正确的邮箱'));
            } else {
                callback();
            }
        };
          // 手机号校验
          let validatePhone = (rule, value, callback) => {
            if (!validPhone(value)) {
                callback(new Error('请输入正确的手机号'));
            } else {
                callback();
            }
        };
        return {
            form: {
                userName: '',
                password: '',//密码
                email: '',//邮件
                phonenumber: '',//手机号
                identifyCode: '',
                roleIds: [3],//角色
                confirmPwd: '',//确认密码
                company:'',//公司名称
                nickName:'',
            },
            formRules: {
                userName: [{
                    required: true,
                    message: '请输入名字',
                    trigger: 'blur'
                }],
                password: [
                    {
                        validator: validatePass, trigger: 'blur',
                        required: true,
                    }
                ],
                confirmPwd: [
                    {
                        validator: validatePass2, trigger: 'blur',
                        required: true,
                    }
                ],
                phonenumber: [{
                    validator: validatePhone,
                    trigger: 'blur',
                    required: true,
                }],
                email: [{
                    validator: validateEmail,
                    trigger: 'blur',
                    required: false,
                }],
                company: [{
                    required: true,
                    message: '请输入公司名称',
                    trigger: 'blur'
                }],
                identifyCode: [{
                    required: true,
                    message: '请输入验证码',
                    trigger: 'blur'
                }],
            },
            identifyCode: '',// 图片验证码
            // 验证码规则
            identifyCodes: '3456789ABCDEFGHGKMNPQRSTUVWXY',
            timer: null,
            countdown: 3,
            dialogVisible: false,//注册成功跳转
            agreement: true,//同意协议
        }
    },
    mounted() {
        //进入页面则刷新验证码图片
        this.refreshCode();
    },
    beforeDestroy() {
        clearInterval(this.timer);
        this.timer = null;
    },
    methods: {
        cancelRe() {
            this.$router.push('/');
        },

        // 切换验证码
        refreshCode() {
            this.identifyCode = ''
            this.makeCode(this.identifyCodes, 4)
        },
        
        // 生成随机验证码
        makeCode(codes, num) {
            for (let i = 0; i < num; i++) {
                this.identifyCode += this.identifyCodes[Math.floor(Math.random() * (this.identifyCodes.length))]
            }
        },

        // 提交创建
        async submitRe() {
            let _that = this;
            if (this.form.identifyCode != this.identifyCode) {
                this.$message.error('验证码输入错误，请重新输入!');
                return;
            }
            if (!this.agreement) {
                this.$message.error('请勾选协议条款！');
                return;
            }
            this.form.nickName=this.form.userName;
            let res = await registerUser(this.form);
            if (res.code === 200) {
                clearInterval(_that.timer);
                _that.dialogVisible = true;
                _that.timer = setInterval(() => {
                    if (_that.countdown > 0) {
                        _that.countdown--;
                    } else {
                        clearInterval(_that.timer);
                        _that.timer = null;
                        _that.dialogVisible = false;
                        _that.$router.push('/login');
                    }
                }, 1000);
            } else {
                this.$message.error('注册失败');
                return;
            }
        },

        openLogin(type) {
            if (type === 'cancel') {
                clearInterval(this.timer);
                this.timer = null;
                this.dialogVisible = false;
            }
            else {
                clearInterval(this.timer);
                this.timer = null;
                this.dialogVisible = false;
                this.$router.push('/login');
            }
        }
    }
} 